@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.head-text {
  font-size: 20px;
}
.text-sm {
  font-size: 12px;
}

a,
a:hover {
  color: #f29e21;
  text-decoration: none;
}

.signup {
  background-color: white;
}

.signup .signup-left {
  background-color: #F6F7FB;
  min-height: 100vh;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #979797;
  font-size: 14px;
}
.footer a {
  color: #000;
}
.react-tel-input .form-control {
  padding: .375rem .75rem .375rem 3.5rem
}

/* Updated (09/01/2023) */
.signup-feature{
	border-radius: 0px!important;
	padding: 5px 0px!important;
	background-color: transparent!important;
	border: none;
}
.signup-checkbox-icon{
	color: #F29E21;
}