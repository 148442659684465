@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

a,
a:hover {
    color: #F29E21;
    text-decoration: none;
}

.btn-primary,
.btn-primary:hover {
    background-color: #F29E21 !important;
    border-color: #F29E21 !important;
}

.signup .signup-left {
    background-color: #F6F7FB;
    min-height: 100vh;
}

.topbar {
    background-color: #F6F7FB;
}

.topbar .logo {
    height: 60px;
}

.topbar .backlink {
    background: #FFFFFF;
    border: 1px solid #CFD4DA;
    border-radius: 30px;
    color: #F29E21;
    font-size: 14px;
    padding: 5px 15px;
    display: inline-block;
}

.topbar .profileicon {
    width: 34px;
    height: 34px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #C4BFBF;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
}

.banner-sec {
    background-color: #F6F7FB;
    padding: 120px 0 50px 0;
}

.banner-sec h1 {
    font-size: 35px;
    font-weight: 600;
}

.plans {
    position: relative;
}

.section-decor {
    background-color: #F6F7FB;
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 275px;
}

.plans .pland-box {
    background: #FFFFFF;
    box-shadow: 0 0 5px 0 #9DB0FF;
    border-radius: 16px;
    padding: 30px;
    min-height: calc(100% - 15px);
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.renew-text {
    color: green;
    font-size: 15px;
    position: relative;
    top: -10px;
}

.plans .pland-box > .list {
    flex: 1;
}

.plans .pland-box h3 {
    font-size: 30px;
    color: #343A41;
    font-weight: 500;
}

.plans .pland-box .name {
    color: #343A41;
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.plans .pland-box .price {
    /* background: #F6F7FB; */
    border-radius: 7px;
    padding: 8px 15px;
    font-size: 35px;
    font-weight: 600;
    display: inline-block;
    margin: 0 auto;
}

.plans .pland-box .list ul {
    list-style: none;
    padding: 0;
}

.plans .pland-box .list li {
    /* padding-left: 1.3em; */
    font-size: 15px;
    margin-bottom: 15px;
    display: flex;
}

.plans .pland-box .list li:before {
    content: "\f058";
    font-family: FontAwesome;
    display: inline-block;
    width: 1.3em;
    color: #f29e21;
    font-size: 15px;
    margin-right: 10px;
}
.plans .pland-box .list li.not_available:before {
    content: "\f057";
    color: #d10f0fbf!important;
}

.plans .select-btn {
    border-color: #495062;
    font-size: 18px;
    color: #495062;
    padding: 8px 25px;
    font-weight: 500;
}

.plans .select-btn:hover,
.plans .select-btn.active {
    background-color: #F29E21;
    color: #fff;
    border-color: #F29E21;
}

.plans .grey-btn,
.plans .grey-btn:hover {
    background-color: #ECEFF8;
    border-color: #ECEFF8;
    font-size: 18px;
    padding: 8px 20px;
    color: #4D505D;
    font-weight: 500;
}

.plan-features h3 {
    font-size: 20px;
    color: #4D505D;
    font-weight: 500;
}

.plan-features table {
    font-size: 14px;
}

.plan-features .thead {
    background-color: #F6F7FB;
    color: #333333;
}

.plan-features .thead th {
    font-weight: 600;
    font-size: 18px;
}

.plan-features .check {
    color: #F29E21;
    font-size: 20px;
}

.plan-features .xmark {
    color: #C5C5C5;
    font-size: 20px;
}

.plan-features .table-striped>tbody>tr:nth-child(odd)>td,
.plan-features .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #ffff;
    --bs-table-accent-bg: #fff;
}

.plan-features .table-striped>tbody>tr:nth-child(even)>td,
.plan-features .table-striped>tbody>tr:nth-child(even)>th {
    background-color: #F6F7FB;
}

.plan-features .table>:not(caption)>*>* {
    padding: 1rem 0.5rem;
}

.td-min-width200 {
	min-width: 200px;
}

.success-popup {

}

.success-popup .icon {
	font-size: 50px;
	color: #7ecc11;
}
.success-popup .btn {
	min-width: 120px;
}


.billing-sec {

}

.billing-sec .tab-pane {
	background: #FFFFFF;
box-shadow: 0 0 5px 0 #9DB0FF;
border-radius: 16px;
}

.billing-sec h4 {
	font-size: 20px;
	color: #353535;
	font-weight: 500;
}
.billing-sec .card-type a{
	display: inline-block;
	padding: 8px;
	margin-right: 10px;
	border-radius: 8px;
}
.billing-sec .card-type img {
	max-height: 22px;
}
.billing-sec .card-type a.active{
	border: 2px solid #F29E21;
}

.billing-sec .success-text {
	color: #64B410;
}
.billing-sec .failed-text {
	color: #E02020;
}
.billing-sec .card-number {
	font-size: 24px;
	font-weight: 500;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #ffffff;
    color: black;
    border-bottom: 3px solid #F29E21;
    border-radius: 2px;
    font-weight: bold;
}

.nav-link,
.nav-link:hover {
    color: black;
}

.footer {
    background-color: #fff;
    border-top: 1px solid #979797;
    font-size: 14px;
}

.footer a {
    color: #000;
}

@media only screen and (max-width: 600px) {
    .topbar .profile-name {
        display: none;
    }
}

.plans .pland-box .current-plan {
    background-color: #F29E21;
    color: #fff;
    text-align: center;
    width: 100%;
    position: absolute;
    top: -23px;
    left: 0pc;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-weight: 600;
    font-size: 18px;
    padding: 7px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: transparent;
}

.btn-black,
.btn-black:hover {
    background-color: black !important;
    color: #fff !important;
}

.month-text {
    font-size: 16px;
}
.coupon-original-price {
    color: #bfbfbf;
    font-weight: 500;
    text-decoration: line-through;
    position: relative;
    top: -10px;
}
.close-popup {
    position: absolute;
    top: 2px;
    right: 15px;
    padding: 4px 0 0;
    color: #000;
    cursor: pointer;
}