@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

body{
    background-color: #ededed8a;
    font-family: 'Poppins', sans-serif;
}
.Loading{
    height: 100vh;
    position: fixed;
    z-index: 10000;
    width: 100vw;
    background-color: #f6f6fb;
}
/* Loading Circle */
.Loading .loader {
    border: 5px solid #dddddd;
    border-top: 5px solid #f29e21;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    animation: spin 2s linear infinite;
  }

 @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .Loading img{
    max-width: 200px;
    width: auto;
  }
/* Loading Text */
  .Loading .status {
	animation: fadeIn 1s linear forwards;
}
.Loading .status__dot {
	animation: appear1 1s 1s steps(1,start) infinite;
	display: inline-block;
}
.Loading .status__dot:nth-child(2) {
	animation: appear2 1s 1s steps(1,start) infinite;
}
.Loading .status__dot:nth-child(3) {
	animation: appear3 1s 1s steps(1,start) infinite;
}
/* Animations */
@keyframes fadeIn {
	from, 3% {opacity: 0;}
	83.3%, to {opacity: 1;}
}
@keyframes appear1 {
	from {visibility: hidden;}
	33%, to {visibility: visible;}
}
@keyframes appear2 {
	from, 33% {visibility: hidden;}
	67%, to {visibility: visible;}
}
@keyframes appear3 {
	from, 67% {visibility: hidden;}
	to {visibility: visible;}
}









  