@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.body {
    background-color: #F6F7FB;
    font-family: 'Poppins', sans-serif;
}

a,
a:hover {
    color: #F29E21;
    text-decoration: none;
}

.btn-primary, .btn-primary:hover {
	background-color: #F29E21 !important;
	border-color: #F29E21 !important;
	font-size: 16px;
}

.notification-screen {
    min-height: calc(100vh - 40px);
}

.notification-screen .signup-notification {
    box-shadow: 0px 0px 2px #8f8d8d;
    background-color: #ffff;
}
.notification-screen .powered img {
    max-width: 60px;
    width: auto;
    position: relative;
    top: -2px;
}

.signup-notification .detail-heading{
    font-size: 22px;
}

.notification-screen .login-detail{
    background-color: aliceblue;
}

.signup-notification .credential-msg{
    font-size: 14px;
}

.footer {
    background-color: transparent;
    border-top: 1px solid #979797;
    font-size: 14px;
}

.footer a {
    color: #000;
}