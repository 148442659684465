/* SIGNUP */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");
body {
  font-family: "Poppins", sans-serif;
}

.head-text {
  font-size: 20px;
}
.text-sm {
  font-size: 12px;
}

.ml-2 {
  margin-left: 10px;
}

a,
a:hover {
  color: #f29e21;
  text-decoration: none;
}

.btn-primary,
.btn-primary:hover {
  background-color: #f29e21 !important;
  border-color: #f29e21 !important;
}

.signup .signup-left {
  background-color: #f6f7fb;
  min-height: 100vh;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #979797;
  font-size: 13px;
}
.footer a {
  color: #000;
}

/* LOGIN */

.body {
  background-color: #f6f7fb;
  font-family: "Poppins", sans-serif;
}
.text-sm {
  font-size: 12px;
}

.Login {
  min-height: 100vh;
}

.Login form {
  width: 340px;
  box-shadow: 0px 0px 2px #8f8d8d;
  background-color: #ffff;
}

.Login form .sign-in {
  font-size: 20px;
}

.Login input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 6px;
  outline: none;
  border: 1px solid #ced4da;
  margin: 5px 0px 15px 0px;
  border-radius: 3px;
}

.Login .one-row-text .remember-btn {
  padding-left: 2px;
}

.Login .one-row-text .forgot {
  text-decoration: none;
  color: #f29e21;
  float: right;
}

.Login .one-row-text .forgot-text {
  flex: auto;
}

.Login form .login-btn {
  background-color: #f29e21;
  padding: 10px 0px;
  border: none;
  color: #ffff;
  width: 100%;
  border-radius: 5px;
  margin: 20px 0px 5px 0px;
}

.Login form .account {
  font-size: 12px;
  margin: 5px 0px;
}

.Login .powered img {
  max-width: 60px;
  width: auto;
  position: relative;
  top: -2px;
}

.footer {
  background-color: #ffff;
  border-top: 1px solid #979797;
  font-size: 14px;
}

.footer a {
  color: #000;
}

/* reset pass */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  background-color: #f6f7fb;
  font-family: "Poppins", sans-serif;
}

.forgot {
  min-height: 93vh;
}

.head-text {
  font-size: 20px;
}

.text-sm {
  font-size: 12px;
}

.forgot-form {
  box-shadow: 0px 0px 1px #8f8d8d;
  background-color: #ffff;
}

a,
a:hover {
  color: #f29e21;
  text-decoration: none;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #979797;
  font-size: 14px;
}
.footer a {
  color: #000;
}

/* verify email  */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  background-color: #f6f7fb;
  font-family: "Poppins", sans-serif;
}

.text-sm {
  font-size: 12px;
}

.verify-user {
  min-height: 94vh;
}

.forgot-form {
  box-shadow: 0px 0px 2px #8f8d8d;
  background-color: #ffff;
}

.mail-text {
  font-size: 14px;
}

.head-text {
  font-size: 20px;
}

a,
a:hover {
  color: #f29e21;
  text-decoration: none;
}

/* verify mail  */

.footer {
  background-color: #fff;
  border-top: 1px solid #979797;
  font-size: 14px;
}

.footer a {
  color: #000;
}
